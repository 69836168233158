/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { ReactElement, useEffect, useState } from 'react'
import axios from 'axios'
import Viewer from './Viewer/Viewer'
import getContract from '../../services/getContract'

const ContractView = (): ReactElement => {
  const [contractFile, setContractFile] = useState<string>('')

  let version: string
  let usageType: string
  let type: string
  let fileAlias: string
  const API_URL = `${process.env.GATSBY_API_URL?.replace('/graphql', '')}`

  if (typeof window !== `undefined`) {
    const myKeys = window.location.search
    const urlParams = new URLSearchParams(myKeys)
    version = urlParams.get('v')!
    fileAlias = urlParams.get('fa')!
    usageType = urlParams.get('ut')!
    if (usageType.includes('House')) {
      usageType = 'House & Lot'
    }
    type = urlParams.get('dt')!
  }
  const getFile = async (fileLink: string): Promise<any> => {
    const file = await axios.get(`${API_URL}/${fileLink}`)
    return file
  }

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const result: any = await getContract({
        version,
        fileAlias,
        usageType,
        type,
      })
      if (result) {
        getFile(result).then((res: any) => {
          setContractFile(res.config.url)
        })
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (contractFile) {
    return <Viewer pdfFile={contractFile} />
  }
  return <div className="mdor" />
}

export default ContractView
